import * as React from "react"
import Navigation from './navigation'
import video from '../images/video/background/video.mp4'
import styled from "styled-components"
import * as variables from "../utilities/style-variables"

const Video = styled.video `
  position: absolute;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  top: 0;
  left: 0;
  z-index: -2;
`

const VideoOverlay = styled.div `
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: ${variables.color_white};
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0.5;
`

const Header = ({noContact, heroVideo}) => {

  return (
    <header>
      <Navigation noContact={noContact} />
      {heroVideo && 
        <>
          <VideoOverlay></VideoOverlay>
          <Video autoPlay muted loop>
            <source src={video} type="video/mp4" />
          </Video>
        </>
      }
      
    </header>
  )
}

export default Header
