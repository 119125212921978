import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "styled-components"
import * as variables from '../utilities/style-variables.js';

const Button = styled.button`
  margin: 0 0 20px 0;
  padding: 10px 15px;
  background-color: ${props => props.type === "primary" ? variables.color_brand_cta: variables.color_brand_secondary};
  color: ${props => props.type === "primary" ? variables.color_white: variables.color_brand_primary};
  border: none;
  border-radius: ${variables.border_radius};
  text-transform: uppercase;
  transition: all ease-in-out 300ms;

  &:hover {
    background-color: ${variables.color_brand_primary};
    color: ${variables.color_white};
  }

  @media (min-width: 768px) { // Medium devices (tablets, 768px and up)
    margin: 0 20px 20px 0;
  } 
`

const Btn = ({type, text, href, inverted, linkToExternal, id}, props) => (
    <Button type={type} inverted={inverted} id={id}>
      {(linkToExternal === true) ?
        <a href={href} target="_blank" rel="noreferrer">{text}</a>
      : 
        <Link to={href}>{text}</Link>
      }
    </Button>
)

Btn.propTypes = {
    type: PropTypes.string,
    text: PropTypes.string,
    href: PropTypes.string,
}

Btn.defaultProps = {
    type: "secondary",
    text: "Mehr Informationen",
    href: "/",
}

export default Btn