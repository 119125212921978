// Globally used style variables

// Colors

export const color_black = '#000000';
export const color_white = '#f6f6f6';
export const color_brand_cta = '#00ADB5';
export const color_brand_primary = '#23334A';
export const color_brand_secondary = '#AAD8D3';
export const color_brand_bg = '#f6f6f6';
export const color_brand_bg_light = '#E6F4F1';
export const color_brand_bg_dark = color_brand_primary;
export const color_brand_warning = '#882100';

// Styles
export const border_radius = '6px';

// Fonts
export const text_font = "'Open Sans', 'sans-serif'";
export const brand_font = "'Albert Sans', 'sans-serif'";

// FONT SIZES
// Mobile
export const font_size__mobile_big = '2.1875em';       // 35px
export const font_size__mobile_medium = '1.5625em';    // 25px
export const font_size__mobile_small = '1.25em';       // 20px
export const font_size__mobile_text = '1em';           // 16px

// Tablet
export const font_size__tablet_big = '2.8125em';       // 45px
export const font_size__tablet_medium = '2.1875em';    // 35px
export const font_size__tablet_small = '1.25em';       // 20px
export const font_size__tablet_text = '1.125em';       // 18px

// Desktop
export const font_size__desktop_big = '4em';           // 65px
export const font_size__desktop_medium = '2.1875em';   // 35px
export const font_size__desktop_small = '1.5625em';    // 25px
export const font_size__desktop_text = '1.125em';      // 18px


// Font weight
export const font_regular = 400;
export const font_semibold = 700;
export const font_bold = 900;
