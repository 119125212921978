import React from 'react'
import { Link } from 'gatsby'

import styled from 'styled-components'
import * as variables from '../utilities/style-variables.js'

const FooterContainer = styled.footer`
    position: relative;
    margin: 0;
    padding: 30px;
    background-color: ${variables.color_brand_bg_dark};
    color: ${variables.color_white};

    @media (min-width: 768px) { // Medium devices (tablets, 768px and up)
        padding: 30px 50px; 
    } 
    @media (min-width: 992px) { // Large devices (desktops, 992px and up)
        padding: 30px;
    }
`

const FooterWrapper = styled.div `
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

const Ul = styled.ul `
    display: flex;
    flex-direction: column;
    justify-content: start;

    &.footer-left-nav{
        justify-content: end;

        li {
            &::after {
            left: auto;
            right: 0;
            }
        }
    }

    @media (min-width: 992px) { // Large devices (desktops, 992px and up)
        flex-direction: row;
    }

`

const Li = styled.li `
        margin: 10px 0;
        position: relative;

        &::after {
        content: "";
        display: inline-block;
        height: 3px;
        width: 0;
        background-color: ${variables.color_brand_cta};
        position: absolute;
        bottom: -6px;
        left: 0;
        transition: all ease-in-out 300ms;
        }

        &:hover {

            &::after {
                width: 100%;
                transition: all ease-in-out 300ms;
            }
        }

        @media (min-width: 992px) { // Large devices (desktops, 992px and up)
            margin: 0 15px;

            &:first-child {
                margin-left: 0;
            }
        }
`

const Footer = () => {
    return (
        <>
            <FooterContainer>
                <FooterWrapper className="container">
                    <Ul>
                        <Li>
                            <Link to="/">Home</Link>
                        </Li>
                        <Li>
                            <Link to="/ueber-showcase21">Über showcase21</Link>
                        </Li>
                    </Ul>
                    <Ul className="footer-left-nav">
                        <Li>
                            <Link to="/datenschutz">Datenschutz</Link>
                        </Li>
                        <Li>
                            <Link to="/impressum">Impressum</Link>
                        </Li>
                    </Ul>
                </FooterWrapper>
            </FooterContainer>
        </>
    )
}

export default Footer
