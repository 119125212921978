import React, {useState} from 'react'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'

import { Link } from "gatsby"
import styled from "styled-components"
import * as variables from '../utilities/style-variables.js';
import Btn from './button.js';
import backArrow from '../images/icons/arrows/icon-arrow-left.svg'

const ConsentBG = styled.div`
  display: none;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  backdrop-filter: blur(5px);
  z-index: 999;

  &.show {
    display: flex;
  }
`

const ConstentContainer = styled.div`
  max-height: 500px;
  position: relative;
  display: none;
  flex-direction: column;
  background-color: ${variables.color_white};
  padding: 20px !important;

  &.show {
    display: flex;
  }
`

const ConsentHeader = styled.div``

const ConsentHeading = styled.h2`
  margin: 20px 0 5px;
  font-size: ${variables.font_size__mobile_small};
  text-align: left;

  @media (min-width: 768px) { // Medium devices (tablets, 768px and up)
    font-size: ${variables.font_size__tablet_small};
  } 

  @media (min-width: 992px) { // Large devices (desktops, 992px and up)
    font-size: ${variables.font_size__desktop_small};      
  }
`

const ConsentParagraph = styled.p`
  font-family: ${variables.text_font};
  font-size: 0.8em;
`

const TextLink = styled(props => <Link {...props} />) `
  text-decoration: underline;
  font-size: inherit;
`

const ConsentBody = styled.div``

const ConsentSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;

  &.small {
    width: 40px;
    height: 22px;
  }
`

const Input = styled.input`
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 2;

  &:checked + .slider {
    background-color: ${variables.color_brand_cta};
  }

  &:checked:disabled + .slider {
    background-color: ${variables.color_brand_secondary};
    opacity: 0.5;
  }

  &:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }

  &:checked + .slider::before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  &:checked + .slider.small::before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }
`

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;

  &::before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: ${variables.color_white};
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }

  &.small {
    border-radius: 20px;

    &::before {
      height: 16px;
      width: 16px;
      bottom: 3px;
    }
  }
`
const ConsentBtns = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media (min-width: 768px) { // Medium devices (tablets, 768px and up)
    flex-direction: row;
    justify-content: flex-end;
  } 
`

const MoreBtn = styled.button`
  margin: 0 0 20px 0;
  padding: 5px 15px;
  background-color: ${variables.color_white};
  color: ${variables.color_brand_cta};
  border-width: 3px;
  text-transform: uppercase;
  border-style: none;
  transition: all ease-in-out 300ms;

  &:hover {
    background-color: ${variables.color_brand_cta};
    color: ${variables.color_white};
  }

  @media (min-width: 768px) { // Medium devices (tablets, 768px and up)
    margin: 0 20px 20px 0;
  } 
`

const BackBtn = styled.button`
  position: absolute;
  width: 15px;
  height: 15px;
  top: 15px;
  border: none;
  background-image: url(${backArrow});
  background-size: contain;
  background-repeat: no-repeat;
`

const DetailView = styled.div`
  overflow-y: scroll;
`

const DetailBox = styled.div``

const HostName = styled.p`
  font-size: 1em;
  font-weight: ${variables.font_bold};
`

const Host = styled.p`
  font-size: .8em;
`

const Detail = styled.p`
  font-size: 0.8em;

  span {
    font-size: 1em;
    display: block;
    font-weight: ${variables.font_bold};
  }

  a{
    font-size: inherit;
  }
`

const CookieConsent = () => {

  // Check if is browser
  const isBrowser = () => typeof window !== "undefined";

  // Page Path for redirect after consent click
  const pagePath = isBrowser() && window.location.pathname;

  // Cookie Consent function 
  const localStorage = isBrowser() && window.localStorage;
  const consentPropertyName = "showcase21_consent";

  const shouldShowPopup = () => !localStorage.getItem(consentPropertyName);
  const saveToStorage = (name, value) => localStorage.setItem(name, value);

  if(isBrowser()) {
    window.onload = () => {
      const consentPopup = document.getElementById("consent-popup");
      const acceptBtn = document.getElementById("accept");
      const denyBtn = document.getElementById("deny");
  
      // FIRST VIEW
      // Save consent agreement to local storage
      const acceptingConsent = (e) => {
        saveToStorage(consentPropertyName, true);
        consentPopup.classList.remove("show");
        window.location.reload();
      }
      const denyConsent = (e) => {
        saveToStorage(consentPropertyName, false);
        consentPopup.classList.remove("show");
        window.location.reload();
      }
  
      // Clicking consent buttons
      acceptBtn.addEventListener('click', acceptingConsent);
      denyBtn.addEventListener('click', denyConsent);
  
      // Show consent if no consent has been given
      if(shouldShowPopup()) {
        consentPopup.classList.add("show");
      }

      // SECOND VIEW
      const acceptSelectionBtn = document.getElementById("accept-selection");
      const acceptAllSelectionBtn = document.getElementById("accept-all-selection");
      const consentGA = document.getElementById("consent-google-analytics");
      const analyticsConsentPropertyName = "analytics_storage_ga";

      // Save consent selection to local storage
      // Google Analytics 
      const acceptConsentSelection = (e) => {
        saveToStorage(consentPropertyName, true);

        if (consentGA.checked) {
          saveToStorage(analyticsConsentPropertyName, true);
        } else {
          saveToStorage(analyticsConsentPropertyName, false);
        } 
        consentPopup.classList.remove("show");
        window.location.reload();
      }

      // Clicking consent selection buttons
      acceptSelectionBtn.addEventListener('click', acceptConsentSelection);
      acceptAllSelectionBtn.addEventListener('click', acceptingConsent);

    }
  }

  // Change to second cookie consent view
  const [isActive, setActive] = useState("false");

  const handleToggle = () => {
    setActive(!isActive);
  }; 

  return (
    <>
      <ConsentBG id="consent-popup">
        <div className="container">
          <div className="row">
            {/* First View - Info */}
            <ConstentContainer className={`col-md-8 offset-md-2 ${isActive ? "show" : ""}`} id="first-view">

              <ConsentHeader>
                <ConsentHeading>Diese Webseite nutzt Cookies</ConsentHeading>
                <ConsentParagraph>
                  Wir verwenden Cookies, um die Zugriffe auf unsere Website zu analysieren. 
                  Außerdem geben wir Informationen zu Ihrer Verwendung unserer Website an unsere Partner für Analysen weiter. Unsere Partner führen diese Informationen 
                  möglicherweise mit weiteren Daten zusammen, die Sie ihnen bereitgestellt haben oder die sie im Rahmen Ihrer Nutzung der Dienste gesammelt haben. Sie willigen gemäß 
                  Art. 49 Abs. 1 lit. a DSGVO darüber hinaus ein, dass auch Anbieter in den USA Ihre Daten verarbeiten. In diesem Fall ist es möglich, dass Ihre Daten durch Behörden, zu Kontroll- 
                  oder Überwachungszwecken, möglicherweise auch ohne Rechtsbehelfsmöglichkeit, verarbeitet werden können. Weitere Informationen finden Sie in  
                  unserer <TextLink to="/datenschutz">Datenschutzerklärung</TextLink>. Dort können Sie Ihre Zustimmung jederzeit widerrufen.
                </ConsentParagraph>
              </ConsentHeader>

              <ConsentBody>
                {/* <ConsentSwitches >

                  <SwitchBox>
                    <ConsentCategory>Essenzielle Cookies</ConsentCategory>
                    <ConsentSwitch>
                      <Input type="checkbox" id="consent-essential" checked disabled></Input>
                      <Slider className="slider"></Slider>
                    </ConsentSwitch>
                  </SwitchBox>

                  <SwitchBox>
                    <ConsentCategory>Analytics Cookies</ConsentCategory>
                    <ConsentSwitch>
                      <Input type="checkbox" id="consent-analytics"></Input>
                      <Slider className="slider"></Slider>
                    </ConsentSwitch>
                  </SwitchBox>
                  
                </ConsentSwitches> */}

                {/* First View - Buttons */}
                <ConsentBtns>
                  <MoreBtn 
                    id="more-details"
                    onClick={handleToggle}
                  >
                    Mehr Details
                  </MoreBtn>
                  <Btn 
                    type="primary"
                    text="Alle Akzeptieren"
                    href={pagePath}
                    id="accept"
                  />
                  <Btn 
                    type="secondary"
                    text="Nur notwendiges"
                    href={pagePath}
                    id="deny"
                  />
                </ConsentBtns>
              </ConsentBody>
            </ConstentContainer>

            {/* Second View - Details */}
            <ConstentContainer className={`col-md-8 offset-md-2 ${isActive ? "" : "show"}`} id="second-view">
              <BackBtn onClick={handleToggle}></BackBtn>
              <ConsentHeader>
                <ConsentHeading>Mehr Infos zu den Cookies</ConsentHeading>
              </ConsentHeader>
              <DetailView>
              
              {/* Second View - Tabs */}
              <Tabs defaultActiveKey="essential" id="uncontrolled-tab-example" className="mb-3">

                {/* Second View - Essential Cookies */}
                <Tab eventKey="essential" title="Essenziell">
                  <ConsentParagraph>
                    Diese Cookies sind für die einwandfreie Funktionalität der Webseite notwendig und speichern keine persönlich identifizierbaren Informationen. Sie werden normalerweise als Folge von Nutzeraktivitäten gesetzt, um wichtige Funktionen wie das Setzen und Aufrechterhalten von Anmeldedaten oder Datenschutzeinstellungen zu ermöglichen. Diese Cookies können normalerweise nicht abgeschaltet werden. Allerdings können bestimmte Browser diese Cookies blockieren oder Sie darauf hinweisen. Seien Sie sich bewusst, dass das Blockieren dieser Cookies die Funktionalität der Webseite beeinträchtigt.
                  </ConsentParagraph>
                  <ul>
                    <li>
                      <DetailBox>
                        <HostName>Google Tag Manager</HostName>
                        <ConsentSwitch className="small">
                          <Input type="checkbox" id="consent-google-tag-manager" className="small" checked disabled></Input>
                          <Slider className="slider small"></Slider>
                        </ConsentSwitch>
                        <Host>Google Ireland Ltd.</Host>
                        <Detail>
                          <span>Zweck</span>
                          Tracking einbinden
                        </Detail>
                        <Detail>
                          <span>Rechtsgrundlage</span>
                          Art. 6 Abs. 1 lit. a
                        </Detail>
                        <Detail>
                          <span>Datenschutzerklärung</span>
                          <a href="https://policies.google.com/privacy?gl=DE&hl=de" target="_blank" rel="noreferrer">https://policies.google.com/privacy?gl=DE&hl=de</a><br/>
                          <a href="https://business.safety.google/gdpr/" target="_blank" rel="noreferrer">https://business.safety.google/gdpr/</a>
                        </Detail>
                        <Detail>
                          <span>Eingesetze Cookies und Laufzeit</span>
                          _ga - 730 Tage
                        </Detail>
                      </DetailBox>
                    </li>
                  </ul>
                </Tab>

                {/* Second View - Analytics Cookies */}
                <Tab eventKey="analytics" title="Analytics">
                  <ConsentParagraph>
                    Diese Cookies sammeln anonyme, aggregierte Daten und ermöglichen es uns, unsere populärsten Seiten und Inhalte zu identifizieren, um die Webseite und das Benutzererlebnis zu verbessern. Die Deaktivierung dieser Cookies wirkt sich negativ auf unsere Bemühungen aus, unsere Dienstleistungen stets zu verbessern.
                  </ConsentParagraph>
                  <ul>
                    <li>
                      <DetailBox>
                        <HostName>Google Analytics</HostName>
                        <ConsentSwitch className="small">
                          <Input type="checkbox" id="consent-google-analytics" className="small"></Input>
                          <Slider className="slider small"></Slider>
                        </ConsentSwitch>
                        <Host>Google Ireland Ltd.</Host>
                        <Detail>
                          <span>Zweck</span>
                          Tracking
                        </Detail>
                        <Detail>
                          <span>Rechtsgrundlage</span>
                          Art. 6 Abs. 1 lit. a
                        </Detail>
                        <Detail>
                          <span>Datenschutzerklärung</span>
                          <a href="https://policies.google.com/privacy?gl=DE&hl=de" target="_blank" rel="noreferrer">https://policies.google.com/privacy?gl=DE&hl=de</a><br/>
                          <a href="https://business.safety.google/gdpr/" target="_blank" rel="noreferrer">https://business.safety.google/gdpr/</a>
                        </Detail>
                        <Detail>
                          <span>Eingesetze Cookies und Laufzeit</span>
                          _gid - 1 Tag<br />
                          _ga - 730 Tage
                        </Detail>
                      </DetailBox>
                    </li>
                  </ul> 
                </Tab>

                {/* Second View - Marketing Cookies */}
                <Tab eventKey="marketing" title="Marekting">
                  <ConsentParagraph>
                    Dieser Cookies-Typ wird von dieser Website nicht verwendet.
                  </ConsentParagraph>
                </Tab>

                {/* Second View - Präferenzen Cookies */}
                <Tab eventKey="preferences" title="Präferenzen">
                  <ConsentParagraph>
                    Dieser Cookies-Typ wird von dieser Website nicht verwendet.
                  </ConsentParagraph>
                </Tab>

              </Tabs>
              {/* Second View - Buttons */}
              <ConsentBtns>
                  <MoreBtn 
                    id="more-details"
                    onClick={handleToggle}
                  >
                    zurück
                  </MoreBtn>
                  <Btn 
                    type="primary"
                    text="Alle akzeptieren"
                    href={pagePath}
                    id="accept-all-selection"
                  />
                  <Btn 
                    type="secondary"
                    text="Auswahl akzeptieren"
                    href={pagePath}
                    id="accept-selection"
                  />
                </ConsentBtns>
              </DetailView>
            </ConstentContainer>

          </div>
        </div>
      </ConsentBG>
    </>
  )
}

export default CookieConsent
